
import { Component, Vue, Prop } from "vue-property-decorator";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { mapGetters } from "vuex";
interface Paginate {
  form: number;
  size: number;
  total: number;
}
interface ItemData {
  id: string;
  game_id: string;
  seamless_id: string;
  seamless_name: string;
  seamless_code: string;
  seamless_image_path: string;
  game_type: string;
  is_game_enable: boolean;
  created_at: number;
  updated_at: number;
  game_data: {
    id: string;
    name: string;
    image_path: string;
  };
}

@Component({
  computed: {
    ...mapGetters(["username", "token"]),
  },
})
export default class WidgetGame extends Vue {
  @Prop() items?: Array<ItemData> | undefined;
  @Prop() paginate?: Paginate | undefined;
  $axios?: AxiosInstance | undefined;
  username?: string | undefined;
  token?: string | undefined;
  $removeCookie: any;

  playGame(item: ItemData) {
    const data: {
      username?: string;
      game_id?: string;
      game_list_id?: string;
      is_mobile?: boolean;
      ip?: string;
      owner?: string;
      redirect_url?: string;
      session_token?: string;
    } = {
      username: this.username,
      game_id: item.game_id,
      game_list_id: item.id,
      is_mobile: this.getDeviceType(),
      owner: item.game_data.name,
      redirect_url: "http://localhost:8080",
    };
    this.$axios
      ?.post("/api/game/play_game", data, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response: AxiosResponse) => {
        const dataResponse = response.data;
        const data = dataResponse.data;
        if (data) {
          window.open(data.url, "_self");
        }
      })
      .catch((error: AxiosError) => {
        const response: any = error.response?.data;
        const message: string = response.message.message;
        if (error?.response) {
          if (error?.response?.status == 401) {
            this.$swal({
              icon: "error",
              text: "Token has been expried. Please login again",
            }).then(() => {
              this.$removeCookie("sm.token");
              this.$store.commit("token", null);
              this.$removeCookie("sm.user");
              this.$store.commit("user", null);
              this.$router.push("/login");
            });
            return;
          } else {
            this.$swal(message);
          }
        } else {
          this.$swal(message);
        }
      });
  }

  getDeviceType(): boolean {
    if (navigator.userAgent.match(/Android/i)) {
      return true; //'Android';
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return true; //'iOS';
    } else if (navigator.userAgent.match(/Windows Phone/i)) {
      return true; //'Windows Phone';
    } else {
      return false;
    }
  }
}
