import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// plugins
import i18n from "./i18n";
import axios from "./axios";
import { BootstrapVue } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import numeral from "numeral";

// css
import "./assets/scss/app.scss";
import "sweetalert2/dist/sweetalert2.min.css";

// plugin use
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use({
  install(Vue) {
    Vue.prototype.$axios = axios;
  },
});
Vue.use({
  install(Vue) {
    Vue.prototype.$numeral = numeral
    Vue.prototype.$removeCookie = async (name: string) => {
      return await new Promise<void | boolean>((resolve) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        resolve(true)
      })
    }
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
