import axios, { AxiosInstance } from "axios";
// import Vue from "vue";

const instance: AxiosInstance = axios.create({
  baseURL: "https://api.axbbet.com", // staging
  // baseURL: "https://api.forallbet.dev", //production
  headers: {
    "Content-Type": "application/json",
  },
});

// interface WindowGlobal extends Window {
//   location: Location;
//   navigator: Navigator;
//   localStorage: Storage;
// }

// declare let window: WindowGlobal;

// instance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// function removeCookie(name: string) {
//   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
// }

export default instance;
