
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import MobileLayout from "@/components/MobileLayout.vue"; // @ is an alias to /src
import Header from "@/components/Header.vue"; // @ is an alias to /src
import WidgetBalance from "@/components/WidgetBalance.vue";
import WidgetGame from "@/components/WidgetGame.vue";
interface Paginate {
  form: number;
  size: number;
  total: number;
}
@Component({
  components: {
    MobileLayout,
    Header,
    WidgetBalance,
    WidgetGame,
  },

  computed: {
    ...mapGetters(["token", "username"]),
  },
})
export default class GameView extends Vue {
  $axios?: AxiosInstance;
  token?: string;
  game_id?: string;
  paginate?: Paginate | undefined;
  from = 0;
  size = 20;
  games?: Array<object> | undefined;
  $removeCookie: any;

  data() {
    return {
      games: [],
      game_id: this.$route.params.brandId,
      paginate: { form: 0, size: 0, total: 0 },
    };
  }
  mounted() {
    this.getGameList();
  }

  loadmore() {
    this.from += this.size;
    this.getGameList(true);
  }

  getGameList(pushItem = false) {
    this.$axios
      ?.get("/api/game/get_game", {
        params: {
          game_id: this.game_id,
          from: this.from,
          size: this.size,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response: AxiosResponse) => {
        const dataResponse = response.data;
        const data = dataResponse.data;
        const games: Array<object> = [];
        for (let i = 0; i < data.game_list_data.length; i++) {
          const game = data.game_list_data[i];
          if (game.is_game_enable) {
            games.push(game);
          }
        }
        if (pushItem) {
          this.games?.push(...games);
        } else {
          this.games = games;
        }
        this.paginate = data.paginate_data;
      })
      .catch((error: AxiosError) => {
        const response: any = error.response?.data;
        const message: string = response.message.message;
        if (error?.response) {
          if (error?.response?.status == 401) {
            this.$swal({
              icon: "error",
              text: "Token has been expried. Please login again",
            }).then(() => {
              this.$removeCookie("sm.token");
              this.$store.commit("token", null);
              this.$removeCookie("sm.user");
              this.$store.commit("user", null);
              this.$router.push("/login");
            });
            return;
          } else {
            this.$swal(message);
          }
        } else {
          this.$swal(message);
        }
      });
  }
}
