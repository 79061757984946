
import { Component, Vue, Prop } from "vue-property-decorator";

interface ItemData {
  id: string;
  name: string;
  image_path: string;
  is_game_enable: boolean;
  created_at: number;
  updated_at: number;
  game_count: number;
  game_type: Array<string>;
  sorting: number;
}

@Component
export default class WidgetBrand extends Vue {
  @Prop() items: Array<ItemData> | undefined;

  linkedTo(id: string) {
    this.$router.push(`/game/${id}`);
  }
}
