
import { Component, Vue } from "vue-property-decorator";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const CryptoJS: any = require('crypto-js');

@Component
export default class LoginView extends Vue {
  username?: string;
  password?: string;
  $axios?: AxiosInstance;
  $removeCookie: any;

  data() {
    return {
      username: "",
      password: "",
    };
  }

  async login() {
    const data: { username: string; password: string } = {
      username: this.username || "",
      password: this.password || "",
    };
    await this.$axios
      ?.post("/api/client/members/v2/login", data)
      .then(async (response: AxiosResponse) => {
        const dataResponse = response.data;
        const data = dataResponse.data;
        this.setCookie("sm.token", data.access_token, data.expires_in);
        this.$store.commit("token", data.access_token);
        this.setCookie("sm.user", data.username, data.expires_in);
        this.$store.commit("user", data.username);
        this.$router.push("/brand");
      })
      .catch((error: AxiosError) => {
        const response: any = error.response?.data;
        const message: string = response.message.message;
        if (error?.response) {
          if (error?.response?.status == 401) {
            this.$swal({
              icon: "error",
              text: message,
            }).then(() => {
              this.$removeCookie("sm.token");
              this.$store.commit("token", null);
              this.$removeCookie("sm.user");
              this.$store.commit("user", null);
              // this.$router.push("/login");
            });
            return;
          } else {
            this.$swal(message);
          }
        } else {
          this.$swal(message);
        }
      });
  }

  async setCookie(name: string, value: string, expireIn: number) {
    const date = new Date();
    date.setTime(date.getTime() + expireIn * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";

    // const key = "your-secret-key-here";
    // const data = value;
    // // Encrypt the data
    // const ciphertext = CryptoJS.AES.encrypt(data, key).toString();
    // console.log("ciphertext:", ciphertext);

    // return await true
  }
}
