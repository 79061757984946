
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import MobileLayout from "@/components/MobileLayout.vue"; // @ is an alias to /src
import Header from "@/components/Header.vue"; // @ is an alias to /src
import WidgetBalance from "@/components/WidgetBalance.vue";
import WidgetBrand from "@/components/WidgetBrand.vue";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";

@Component({
  components: {
    MobileLayout,
    Header,
    WidgetBalance,
    WidgetBrand,
  },
  computed: {
    ...mapGetters(["token", "username"]),
  },
})
export default class BrandView extends Vue {
  $axios?: AxiosInstance;
  token?: string;
  brands?: Array<object> | undefined;
  $removeCookie: any;

  data() {
    return {
      brands: [],
    };
  }

  mounted() {
    this.getBrandList();
  }

  getBrandList() {
    this.$axios
      ?.get("/api/game/get_brand", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response: AxiosResponse) => {
        const dataResponse = response.data;
        const data = dataResponse.data;
        const brands: Array<object> = [];
        for (let i = 0; i < data.brand_data.length; i++) {
          const brand = data.brand_data[i];
          if (brand.is_game_enable) {
            brands.push(brand);
          }
        }
        this.brands = brands;
      })
      .catch((error: AxiosError) => {
        const response: any = error.response?.data;
        const message: string = response.message.message;
        if (error?.response) {
          if (error?.response?.status == 401) {
            this.$swal({
              icon: "error",
              text: "Token has been expried. Please login again",
            }).then(() => {
              this.$removeCookie("sm.token");
              this.$store.commit("token", null);
              this.$removeCookie("sm.user");
              this.$store.commit("user", null);
              this.$router.push("/login");
            });
            return;
          } else {
            this.$swal(message);
          }
        } else {
          this.$swal(message);
        }
      });
  }
}
