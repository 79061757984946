
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["username"]),
  },
})
export default class Header extends Vue {
  username?: string;
  $removeCookie: any;
  async logout() {
    await this.$removeCookie("sm.token");
    this.$store.commit('token', null)
    await this.$removeCookie("sm.user");
    this.$store.commit('user', null)
    this.$router.push("/login");
  }
}
