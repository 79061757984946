// i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en", // set default locale
  messages: {
    en: require("./langs/en"),
    th:  require("./langs/th"),
  },
});
