// import Vue from "vue";
import { NavigationGuard } from "vue-router";
import store from '../store'

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
}

export const auth: NavigationGuard = (to, from, next) => {

  const token = getCookie("sm.token");
  const user = getCookie("sm.user");
  store.commit('token', token)
  store.commit('user', user)
  if (token) {
    next();
  } else {
    next("/login");
  }
};

export const notLogin: NavigationGuard = (to, from, next) => {
  const token = getCookie("sm.token");
  const user = getCookie("sm.user");
  store.commit('token', token)
  store.commit('user', user)
  if (token) {
    next('/brand');
  } else {
    next();
  }
};
