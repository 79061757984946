
import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse, AxiosError, AxiosInstance } from "axios";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["token", "username"]),
  },
})
export default class WidgetBalance extends Vue {
  username?: string;
  token?: string;
  balance?: number;
  currency?: string;
  $axios?: AxiosInstance;
  $removeCookie: any;
  $numeral?: any;

  data() {
    return {
      balance: 0,
      currency: "",
    };
  }

  mounted() {
    this.getBalance();
  }

  getBalance() {
    const params: { username?: string; wallet_id: number } = {
      // username: this.username,
      wallet_id: 1001,
    };

    // this.$axios?.defaults.headers.common['Authorization']: AxiosHeaders = `Bearer ${this.token}`
    this.$axios
      ?.get("/api/wallet/members/balances", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params,
      })
      .then((response: AxiosResponse) => {
        const dataResponse = response.data;
        const data = dataResponse?.data;
        this.balance = data?.list[0]?.balance;
        this.currency = data?.list[0]?.currency;
      })
      .catch((error: AxiosError) => {
        const response: any = error.response?.data;
        const message: string = response.message.message;
        if (error?.response) {
          if (error?.response?.status == 401) {
            this.$swal({
              icon: "error",
              text: "Token has been expried. Please login again",
            }).then(() => {
              this.$removeCookie("sm.token");
              this.$store.commit("token", null);
              this.$removeCookie("sm.user");
              this.$store.commit("user", null);
              this.$router.push("/login");
            });
            // window.location.href = "/login";
            return;
          } else {
            this.$swal(message);
          }
        } else {
          this.$swal(message);
        }
      });
  }
}
