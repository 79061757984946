import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import BrandView from "../views/BrandView.vue";
import GameView from "../views/GameView.vue";
import ChnagePasswordView from "../views/ChnagePasswordView.vue";

// middleweare
import { auth, notLogin } from "@/middleware";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: BrandView,
    beforeEnter: auth,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: notLogin,
  },
  {
    path: "/brand",
    name: "brand",
    component: BrandView,
    beforeEnter: auth,
  },
  {
    path: "/game/:brandId",
    name: "game-brand",
    component: GameView,
    beforeEnter: auth,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChnagePasswordView,
    beforeEnter: auth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
