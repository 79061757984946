import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
  },
  getters: {
    token(state) {
      return state.token;
    },
    username(state) {
      return state.user;
    },
  },
  mutations: {
    token(state, payloads) {
      state.token = payloads;
    },
    user(state, payloads) {
      state.user = payloads;
    },
  },
  actions: {},
  modules: {},
});

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
}
