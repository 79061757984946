
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import MobileLayout from "@/components/MobileLayout.vue"; // @ is an alias to /src
import Header from "@/components/Header.vue"; // @ is an alias to /src
interface Password {
  password?: string;
  new_password?: string;
  confirm_password?: string;
}
@Component({
  components: {
    MobileLayout,
    Header,
  },
  computed: {
    ...mapGetters(["token", "username"]),
  },
})
export default class ChnagePasswordView extends Vue {
  $axios?: AxiosInstance;
  token?: string;
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
  $removeCookie: any;

  data() {
    return {
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  changePaword() {
    const data: Password = {
      password: this.password,
      new_password: this.newPassword,
      confirm_password: this.confirmPassword,
    };
    this.$axios
      ?.post("/api/client/members/v2/change-pass", data, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response: AxiosResponse) => {
        const dataResponse = response.data;
        // const data = dataResponse.data;
        if (dataResponse.status.code == 200) {
          this.$swal.fire({
            icon: "success",
            title: "Success",
            text: "Change Password Successfull.",
          });
          this.password = "";
          this.newPassword = "";
          this.confirmPassword = "";
        }
      })
      .catch((error: AxiosError) => {
        const response: any = error.response?.data;
        const message: string = response?.message?.message || error.message;
        if (error?.response) {
          if (error?.response?.status == 401) {
            this.$swal({
              icon: "error",
              text: "Token has been expried. Please login again",
            }).then(() => {
              this.$removeCookie("sm.token");
              this.$store.commit("token", null);
              this.$removeCookie("sm.user");
              this.$store.commit("user", null);
              this.$router.push("/login");
            });
            return;
          } else {
            this.$swal(message);
          }
        } else {
          this.$swal(message);
        }
      });
  }
}
